<template>
  <div>
    <h2>アカウント管理</h2>
    <pv-data-table ref="dt" :value="users" :rows="10" :totalRecords="totalRecords"
      :loading="loading" paginator lazy @page="loadUsers" @sort="loadUsers">
      <template #header>
        <div class="flex justify-content-between">
          <div class="flex justify-content-start">
            <input-text placeholder="検索ワード" icon="search" v-model="filterWord" />
            <pv-dropdown class="ml-1" v-model="filterType" :options="types" optionLabel="label" optionValue="value" placeholder="タイプ" />
          </div>
          <div class="flex justify-content-end">
            <pv-button label="登録" icon="pi pi-plus" @click="userDialog = true" />
            <pv-button class="ml-2 p-button-warning" icon="pi pi-upload" v-tooltip="'CSVファイルをアップロードして登録'" @click="file.click()" />
            <input v-show="false" ref="file" type="file" accept="*.csv" @change="uploadFile" />
          </div>
        </div>
      </template>
      <pv-column field="login_id" header="ID" sortable />
      <pv-column field="email" header="メールアドレス" sortable />
      <pv-column field="created_at" header="登録日時" sortable>
        <template #body="{data}">
          {{ dayjs(data.created).format('YYYY/MM/DD HH:mm') }}
        </template>
      </pv-column>
      <pv-column field="role" header="タイプ">
        <template #body="{data}">
          {{ UserRole[data.role] }}
        </template>
      </pv-column>
      <pv-column field="id">
        <template #header>
          <pv-button class="p-button-warning" icon="pi pi-download" v-tooltip="'データをダウンロード'" @click="downloadUsers" />
        </template>
        <template #body="{data}">
          <pv-button class="ml-2 p-button-text" icon="pi pi-pencil" @click="editUser(data)" />
          <pv-button class="ml-2 p-button-danger p-button-text" icon="pi pi-trash" @click="removeUser(data)" />
        </template>
      </pv-column>
    </pv-data-table>
  </div>

  <pv-dialog v-model:visible="userDialog" :header="user.id ? 'アカウント編集' : 'アカウント登録'" modal :closable="false" :style="{width: '420px'}">
    <form class="mt-1" @submit.prevent="updateUser">
      <div class="formgrid grid">
        <label class="col-4 input-label">ID</label>
        <div class="col-8 field">
          <div class="p-input-icon-right w-full">
            <i class="pi pi-user" />
            <input-text type="text" v-model="user.login_id" :error="error.login_id" class="w-full" required />
          </div>
        </div>
      </div>
      <div class="formgrid grid">
        <label class="col-4 input-label">メールアドレス</label>
        <div class="col-8 field">
          <div class="p-input-icon-right w-full">
            <i class="pi pi-envelope" />
            <input-text type="email" v-model="user.email" :error="error.email" class="w-full" required />
          </div>
        </div>
      </div>
      <div class="formgrid grid">
        <label class="col-4">ユーザータイプ</label>
        <div class="col-8 formgroup-inline">
          <pv-radio label="スタッフ" value="staff" v-model="user.role" />
          <pv-radio label="事務局" value="admin" v-model="user.role" />
        </div>
      </div>

      <input v-show="false" ref="formSubmit" type="submit" />
    </form>

    <template #footer>
      <pv-button label="取消" class="p-button-text" @click="cancel" />
      <pv-button :label="user.id ? '更新' : '登録'" @click="formSubmit.click()" />
    </template>
  </pv-dialog>

  <pv-dialog header="アカウント削除" v-model:visible="confirmDelete" modal :closable="false">
    <div class="confirmation-content">
      <strong>{{ delUser.email }}</strong><br />
      このアカウントを削除します。<br />よろしいですか？
    </div>
    <template #footer>
      <pv-button label="取消" class="p-button-text" @click="confirmDelete = false" />
      <pv-button label="削除" @click="removeUser(delUser, true)" />
    </template>
  </pv-dialog>
</template>

<style scoped>
::v-deep(.p-dropdown) {
  max-height: 2.5em;
}
::v-deep(.field > .p-float-label > label) {
  font-weight: normal!important;
}
::v-deep(.p-column-header-content) {
  position: relative;
}
::v-deep(th:last-of-type > .p-column-header-content) {
  float: right;
}
::v-deep(.p-column-header-content > .p-sortable-column-icon) {
  position: absolute;
  left: -27px;
}

.flex.justify-content-end {
  max-height: 2.5em;
}
</style>

<script>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { getUsers, postUser, uploadUserCSV, deleteUser } from "@/common/api";
import { UserRole } from "@/common/master";

export default {
  layout: "app",
  setup() {
    const store = useStore();
    const types = ref([
      { label: "権限", value: "all" },
      { label: "スタッフ", value: "staff" },
      { label: "事務局", value: "admin" },
    ]);
    const filterType = ref("all");
    const filterWord = ref("");
    watch(() => [filterType.value, filterWord.value], async () => {
      await loadUsers(userParams.value);
    });

    const dt = ref();
    const loading = ref(false);
    const totalRecords = ref(0);
    const users = ref([]);
    const userParams = ref({});

    onMounted(async () => {
      await loadUsers({
        first: 0,
        rows: dt.value.rows,
        sortField: null,
        sortOrder: null,
      });
    });

    async function loadUsers(params) {
      if (!store.getters.isLogin) return;
      loading.value = true;
      userParams.value = params;
      params.type = filterType.value;
      params.word = filterWord.value;
      let data = await getUsers(params);

      users.value = data.users;
      totalRecords.value = data.total;
      loading.value = false;
    }

    const userDialog = ref(false);
    const user = ref({ role: "staff" });
    const error = ref({ login_id: "", email: "" });
    const formSubmit = ref();

    watch(() => userDialog.value, (dialog) => {
      if (!dialog) {
        user.value = { role: "staff" };
      }
    });

    const toast = useToast();

    async function updateUser() {
      let data = await postUser(user.value);
      if (data.error) {
        if (data.error == "login_id already exists") {
          error.value.login_id = "既に登録済みのIDです";
        }
        if (data.error == "email already exists") {
          error.value.email = "既に登録済みのメールアドレスです";
        }
        return;
      }

      if (user.value.id) {
        toast.add({ severity: "success", summary: "アカウント更新", detail: "アカウントを更新しました", life: 3000 });
      } else {
        toast.add({ severity: "success", summary: "アカウント登録", detail: "アカウントを登録しました", life: 3000 });
      }

      userDialog.value = false;

      await loadUsers(userParams.value);
    }

    function editUser(u) {
      user.value = {...u};
      userDialog.value = true;
    }

    function cancel() {
      user.value = {};
      error.value = {};
      userDialog.value = false;
    }

    const confirmDelete = ref(false);
    const delUser = ref({});

    async function removeUser(u, force=false) {
      if (!force) {
        delUser.value = u;
        confirmDelete.value = true;
        return;
      }

      await deleteUser(u.id);
      confirmDelete.value = false;
      await loadUsers(userParams.value);
    }

    function downloadUsers() {
      location.href = "/api/users/download/csv";
    }

    const file = ref();

    async function uploadFile(ev) {
      let data = await uploadUserCSV(ev.target.files[0]);
      file.value.value = "";
      if (data.error) {
        toast.add({ severity: "error", summary: "ユーザー一括更新", detail: data.error, life: 10000 });
        return;
      }
      await loadUsers(userParams.value);

      toast.add({ severity: "success", summary: "ユーザー一括更新", detail: "アカウントを一括更新しました", life: 3000 });
    }

    return {
      dt, loading, totalRecords, types, users, filterType, filterWord,
      userDialog, user, UserRole, formSubmit, error, cancel,
      confirmDelete, delUser, removeUser,
      file, uploadFile,
      loadUsers, updateUser, editUser, downloadUsers,
    };
  }
}
</script>
