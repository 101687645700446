export const UserRole = {
    "admin": "事務局",
    "staff": "スタッフ",
};

export const SubjectResults = [
    { label: "判定結果", value: "" },
    { label: "高", value: "high" },
    { label: "中", value: "middle" },
    { label: "低", value: "low" },
];

export const LabelType = {
    "id": "ID",
    "attr": "属性",
};

export const CharType = {
    "all": "全て",
    "hira": "ひらがな",
    "kana": "カタカナ",
    "alphabet": "英字",
    "digit": "数字",
    "sign": "記号",
};

export const CharWidth = {
    "half": "半角",
    "full": "全角",
};